<template>
	<el-dialog
		:title="!dataForm.probId ? '问题登记' : '问题修改'"
		:close-on-click-modal="false"
		:visible.sync="visible"
		width="1200px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="90px" :inline="true">
			<el-row>
				<el-col :span="12">
					<el-form-item label="标的物"  prop="subjectNo">
						<el-select class="selItemInput" v-model="dataForm.subjectNo" placeholder="请选择">
							<el-option v-for="item in $store.state.common.navBarSubjectList" :key="item.displayValue"
									   :label="item.displayName" :value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="问题来源" prop="probSource">
						<el-select class="selItemInput" v-model="dataForm.probSource" placeholder="请选择">
							<el-option
							  v-for="item in problemTypeList"
							  :key="item.displayValue"
							  :label="item.displayName"
							  :value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="提出时间" prop="poseTime">
						<el-date-picker  class="selItemInput"
						  v-model="dataForm.poseTime"
						  type="date"
						  value-format="yyyy-MM-dd"
						  placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
<!--				<el-col :span="12">-->
<!--					<el-form-item label="提出人">-->
<!--						<el-input class="selItemInput1" type="input" v-model="dataForm.poseMan" placeholder="请填写"></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
				<el-col :span="12">
					<el-form-item label="工作范畴" prop="category">
						<el-select class="selItemInput" v-model="dataForm.category" placeholder="请选择">
							<el-option
							  v-for="item in categoryList"
							  :key="item.displayValue"
							  :label="item.displayName"
							  :value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="专业" prop="major">
						<el-select class="selItemInput"  v-model="dataForm.major" placeholder="请选择">
							<el-option
							  v-for="item in majorList"
							  :key="item.displayValue"
							  :label="item.displayName"
							  :value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item prop="probContext">
						<span slot="label">{{ getProbContextTitle() }}</span>
						<el-input class="selItemInput descAreaLg" type="textarea" rows="3" v-model="dataForm.probContext" placeholder="请填写内容"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="问题描述及整改意见" prop="sutContent">
						<el-input class="selItemInput descAreaLg" type="textarea" rows="3" v-model="dataForm.sutContent" placeholder="请填写问题描述及整改意见"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="问题照片">
						<div class="addImgBox" v-for="(item,index) in dataForm.photoList">
						    <el-image
						      style="width: 100%; height: 100%"
						      :src="item.fileUri"
						      fit="cover"></el-image>
							<div class="delBox">
								<i class="el-icon-delete" @click="delImg(index)"></i>
							</div>
						</div>
						<el-upload
						  class="addImgBox"
						  ref="uploadImg"
						  :action="action"
						  :http-request="uploadImg"
						  accept="image/jpeg,image/png,image/jpg">
						  <el-image
						    style="width: 100%; height: 100%"
						    :src="addImg"
						    fit="cover">
						  </el-image>
						</el-upload>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="落实时间">
						<el-date-picker  class="selItemInput"
						  v-model="dataForm.implTime"
						  type="date"
						  value-format="yyyy-MM-dd"
						  placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="落实人">
						<el-input class="selItemInput" type="input" v-model="dataForm.implMan" placeholder="请填写"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			  <el-row class="formBtnGroup">
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button>
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
			  </el-row>
		</el-form>
	</el-dialog>
</template>

<script>
	import addImg from "@/assets/images/addImg.png"
	import $common from "@/utils/common.js"
	export default {
	  name: "problem-add-or-update",
	  data() {
	    return {
			addImg: addImg,
			id: "",
			visible: true,
			form:{},
			action: "",
			problemTypeList:[],
			categoryList:[],
			// subjectList:[],
			majorList:[],
			dataForm:{
				workNo: $common.getItem("workNo"),
				probId:"",
				probSource:"",
				poseMan:"",
				poseTime:"",
				category:"",
				subjectNo:"",
				major:"",
				probContext:"",
				photoList:[],
				implMan:"",
				implTime:"",
				sutContent:""
			},
			dataRule: {
				subjectNo: [
					{ required: true, message: "请选择标的物", trigger: "change" }
				],
				probSource: [
					{ required: true, message: "请选择问题来源", trigger: "change" }
				],
				poseTime: [
					{ required: true, message: "请选择提出时间", trigger: "change" }
				],
				category: [
					{ required: true, message: "请选择工作范畴", trigger: "change" }
				],
				major: [
					{ required: true, message: "请选择专业", trigger: "change" }
				],
				probContext: [
					{ required: true, message: "请填写内容", trigger: "change" }
				],
				sutContent: [
					{ required: true, message: "请填写问题描述及整改意见", trigger: "change" }
				],
			},
	    };
	  },
	  components: {

	  },
	  activated() {

	  },
	  methods: {
		init(id) {
			this.getProblemTypeList();
			this.getCategoryList();
			this.getMajorList()
			this.dataForm = {
				workNo: $common.getItem("workNo"),
				probId:"",
				probSource:"",
				poseMan:"",
				poseTime:"",
				category:"",
				subjectNo:"",
				major:"",
				probContext:"",
				photoList:[],
				implMan:"",
				implTime:"",
				sutContent:""
			}
			this.dataForm.probId = id?id:0;
			this.dataForm.poseMan = $common.getItem("userName");
			this.dataForm.poseTime = this.getCurrentTime();
			this.dataForm.implMan = $common.getItem("userName");
			this.dataForm.implTime = this.getCurrentTime();
			if(id){
				this.getDetail();
			}
			this.$nextTick(() => {
				this.$refs['dataForm'].clearValidate();
				this.visible = true;
			})

		},
		  getCurrentTime() {
			  //获取当前时间并打印
			  var _this = this;
			  let yy = new Date().getFullYear();
			  let mm = new Date().getMonth()+1;
			  let dd = new Date().getDate();
			  let hh = new Date().getHours();
			  let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
			  let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
			  return yy+'-'+mm+'-'+dd;
		  },
		getProblemTypeList(){
			this.$http({
			  url:  this.$store.state.httpUrl + "/common/getProblemType",
			  method: "get",
			  params: {},
			}).then(({ data }) => {
			  if (data && data.resultCode === 200) {
				  this.problemTypeList = data.body;
			  }
			});
		},
		getProbContextTitle(){
			if (this.dataForm.probSource == '0') {
				return '巡检内容'
			}
			if (this.dataForm.probSource == '1') {
				return '报验内容'
			}
			return '内容'
		},
		getCategoryList(){
			this.$http({
			  url:  this.$store.state.httpUrl + "/common/getCategoryList",
			  method: "get",
			  params: {},
			}).then(({ data }) => {
			  if (data && data.resultCode === 200) {
				  this.categoryList = data.body;
			  }
			});
		},
		// getSubjectDropDown(){
		// 	this.$http({
		// 	  url:  this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
		// 	  method: "get",
		// 	  params: {},
		// 	}).then(({ data }) => {
		// 	  if (data && data.resultCode === 200) {
		// 		this.subjectList = data.body;
		// 	  }
		// 	});
		// },
		getMajorList(){
			this.$http({
			  url:  this.$store.state.httpUrl + "/common/getMajorByWorkNo/" + this.dataForm.workNo,
			  method: "get",
			  params: {},
			}).then(({ data }) => {
			  if (data && data.resultCode === 200) {
				this.majorList = data.body;
			  }
			});
		},
		getDetail(){
			this.$http({
			  url: this.$store.state.httpUrl + "/business/probleminfo/info/"+this.dataForm.probId,
			  method: "get",
			  params: {},
			}).then(({ data }) => {
			  if (data && data.resultCode === 200) {
				this.dataForm = data.body;
			  }
			});
		},
		delImg(index){
			let _photoList = this.dataForm.photoList;
			_photoList.splice(index,1);
			this.dataForm.photoList = _photoList;
		},
		uploadImg(param){
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadImg.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/"+$common.getItem("workNo")+"/10/0",
				formData,
				{
				  headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
			  res = res.data
			  if (res.resultCode == 200){
				this.dataForm.photoList.push({
					photoName: res.body.fileName,
					photoPath: res.body.fileSavePath,
					fileUri: res.body.fileUri
				});
			  } else {
				this.$message.info('文件上传失败')
			  }
			})
		},
		dataFormSubmit() {
		  this.$refs["dataForm"].validate(valid => {
		    if (valid) {
			  let _url = "/business/probleminfo/save";
			  if(this.dataForm.probId && this.dataForm.probId != 0){
				_url = "/business/probleminfo/update";
			  }
		      this.$http({
		        url: this.$store.state.httpUrl + _url,
		        method: "post",
		        data: this.$http.adornData(this.dataForm)
		      }).then(({ data }) => {
		        if (data && data.resultCode === 200) {
		          this.$message({
		            message: "操作成功",
		            type: "success",
		            duration: 1500,
		            onClose: () => {
		              this.visible = false;
		              this.$emit("refreshDataList");
		            }
		          });
		        } else {
		          this.$message.error(data.msg);
		        }
		      });
		    }
		  });
		}
	  }
	};
</script>

<style lang="scss" scoped="scoped">
	.selItemInput.descAreaLg{
		width: 1020px !important;
	}
	/*.selItemInput1.inputLg {*/
	/*	width: 200px !important;*/
	/*}*/
	.el-input__inner {
		border-color: #c7d0e1 !important;
		color: #00428e;
		width: 200px !important;
		box-sizing: border-box;
	}
	.addImgBox{
		width: 136px;
		height: 136px;
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
		position: relative;
	}
</style>
