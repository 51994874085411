<template>
	<!-- 问题意见清单 -->
	<div class="mod-config">
		<el-card body-style="padding-bottom: 0;">
			<el-form ref="form" :model="form" inline label-width="80px" label-position="left">
				<el-row>
					<el-col :span="24">
						<el-form-item label="问题来源" style="width: 350px;">
							<el-select class="selItemInput" v-model="probSource" placeholder="请选择">
								<el-option v-for="item in problemTypeList" :key="item.displayValue"
									:label="item.displayName" :value="item.displayValue">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="范畴">
							<el-select class="selItemInput" v-model="category" placeholder="请选择">
								<el-option v-for="item in categoryList" :key="item.displayValue" :label="item.displayName"
									:value="item.displayValue">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="标的物">
							<el-select class="selItemInput" v-model="subjectNo" placeholder="请选择" clearable>
								<el-option v-for="item in $store.state.common.navBarSubjectList" :key="item.displayValue"
									:label="item.displayName" :value="item.displayValue">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="专业">
							<el-select class="selItemInput" v-model="major" placeholder="请选择">
								<el-option v-for="item in majorList" :key="item.displayValue" :label="item.displayName"
									:value="item.displayValue">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="状态" style="width: 350px;">
							<el-select class="selItemInput" v-model="stateFlg" placeholder="请选择">
								<el-option v-for="item in stateList" :key="item.displayValue" :label="item.displayName"
									:value="item.displayValue">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="提出时间">
							<el-date-picker class="selItemInput" v-model="poseTime" type="daterange"
								value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期"
								end-placeholder="结束日期">
							</el-date-picker>
						</el-form-item>
						<el-form-item label="封闭时间">
							<el-date-picker class="selItemInput" v-model="closeTime" type="daterange"
								value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期"
								end-placeholder="结束日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="24" class="selBtnGroup" style="margin-bottom: 15px">
						<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain
							@click="resetFormData()">重置</el-button>
						<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="selGetDataList()"
							v-if="showSearch">检索</el-button>
					</el-col>
				</el-row>
			</el-form>
		</el-card>
		<el-row style="margin-top: 10px;">
			<el-col :span="pageSpan">
				<el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-edit"
					@click="importantProblemHandle()" v-if="showImportant">标记重要程度</el-button>
				<el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-download"
					@click="downloadHandle()">导出</el-button>
				<el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-circle-plus-outline"
					@click="addOrUpdateHandle()" v-if="showAdd">登记</el-button>
			</el-col>
		</el-row>
		<div>
			<el-card style="margin-top: 10px;" body-style="height: 100%; padding: 0;">
				<el-row style="margin-bottom: 10px;">
					<el-table ref="dataListTable" class="dataListTable" :data="dataList" header-align="center"
						style="width: 100%; margin-bottom: 20px;" :row-class-name="tableRowClassName"
						header-cell-class-name="dataListTHeader" @selection-change="handleSelectionChange"
						:cell-style="{ padding: '0px' }">
						<el-table-column type="selection" width="50" align="center">
						</el-table-column>
						<el-table-column type="index" label="序号" width="60" align="center">
							<template slot-scope="scope">{{ (pageIndex2 - 1) * pageSize + scope.$index + 1 }}</template>
						</el-table-column>
						<el-table-column prop="categoryName" label="范畴" width="80" align="center">
						</el-table-column>
						<el-table-column prop="subjectName" label="标的物" width="100" align="center">
						</el-table-column>
						<el-table-column prop="majorName" label="专业" width="80" align="center">
						</el-table-column>
						<el-table-column prop="probContext" label="巡视/报验内容" align="left" show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="sutContent" label="问题描述及整改意见" align="left" show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="iptFlgName" label="重要程度 " width="100" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.iptFlg == 0">普通</span>
								<span v-if="scope.row.iptFlg == 1" style="color: #e7b243;">重要</span>
								<span v-if="scope.row.iptFlg == 2" style="color: #e70013;">非常重要</span>
							</template>
						</el-table-column>
						<el-table-column prop="poseMan" label="提出人 " show-overflow-tooltip width="100" align="center">
						</el-table-column>
						<el-table-column prop="poseTime" width="100" label="提出时间" align="center">
						</el-table-column>
						<el-table-column align="center" label="状态" width="100">
							<template slot-scope="scope">
								<span v-if="scope.row.stateFlg == 0">未封闭</span>
								<span v-if="scope.row.stateFlg == 1">已封闭</span>
								<span v-if="scope.row.stateFlg == 2">取消</span>
							</template>
						</el-table-column>
						<el-table-column prop="closeTime" label="封闭时间" width="100" align="center">
						</el-table-column>
						<el-table-column label="操作" width="280"
							v-if="showLook || showEdit || showClose || showCancel || showLook" align="left">
							<template slot-scope="scope">
								<el-button v-preventReClick type="text" size="mini"
									@click="addOrUpdateHandle(scope.row.probId)"
									v-if="showEdit && scope.row.stateFlg == '0' && !scope.row.testId">修改</el-button>
								<el-button v-preventReClick type="text" size="mini"
									@click="closeProblemHandle(scope.row.probId)"
									v-if="showClose && scope.row.stateFlg == '0'">封闭</el-button>
								<el-button v-preventReClick type="text" size="mini"
									@click="cancelProblemHandle(scope.row.probId)"
									v-if="showCancel && scope.row.stateFlg == '0'">取消</el-button>
								<el-button v-preventReClick type="text" size="mini"
									@click="lookProblemHandle(scope.row.probId)"
									v-if="showLook && scope.row.stateFlg != '0'">详情</el-button>
								<el-button v-preventReClick type="text" size="mini"
									@click="problemRecordHandle(scope.row.probId)"
									v-if="scope.row.stateFlg === '0'">跟踪记录</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination class="my-pagination" @size-change="sizeChangeHandle"
						@current-change="currentChangeHandle" :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]"
						:page-size="pageSize" :total="totalPage" layout="total, sizes, prev, pager, next, jumper">
					</el-pagination>
				</el-row>
			</el-card>
		</div>
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList()"></add-or-update>
		<close-problem v-if="closeProblemVisible" ref="closeProblem" @refreshDataList="getDataList()"></close-problem>
		<cancel-problem v-if="cancelProblemVisible" ref="cancelProblem" @refreshDataList="getDataList()"></cancel-problem>
		<!--	  <problem-info v-if="problemInfoVisible" ref="problemInfo" @refreshDataList="getDataList()"></problem-info>-->
		<important-problem v-if="importantProblemVisible" ref="importantProblem"
			@refreshDataList="getDataList()"></important-problem>
		<problem-record v-if="problemRecordVisible" ref="problemRecord" @refreshDataList="getDataList()"></problem-record>
	</div>
</template>
<script>
import AddOrUpdate from './problem-add-or-update'
import CloseProblem from './problem-close'
import CancelProblem from './problem-cancel'
// import ProblemInfo from './problem-info'
import ImportantProblem from './problem-important'
import $common from "@/utils/common.js"
import ProblemRecord from "./problem-record";
export default {
	data() {
		return {
			showSearch: false,
			showAdd: false,
			showEdit: false,
			showClose: false,
			showCancel: false,
			showLook: false,
			showImportant: false,
			showDelete: false,
			pageSpan: 12,
			tabIndex: "1",
			form: {},
			addOrUpdateVisible: false,
			closeProblemVisible: false,
			cancelProblemVisible: false,
			importantProblemVisible: false,
			problemInfoVisible: false,
			problemRecordVisible: false,
			categoryList: [],
			// subjectList:[],
			majorList: [],
			dataList: [],
			tableSelVal: [],
			tableSelIdArray: [],
			selAllFlg: false,
			category: '',
			subjectNo: "",
			major: "",
			poseTime: [],
			stateFlg: '',
			closeTime: [],
			//当前页码
			pageIndex: 1,
			// 显示序号
			pageIndex2: 1,
			//当前记录数
			pageSize: 10,
			//总页数
			totalPage: 0,
			probSource: '',
			problemTypeList: [],
			stateList: [{
				displayName: '未封闭',
				displayValue: '0'
			}, {
				displayName: '已封闭',
				displayValue: '1'
			}, {
				displayName: '取消',
				displayValue: '2'
			}]
		};
	},
	components: {
		ProblemRecord,
		AddOrUpdate,
		CloseProblem,
		CancelProblem,
		ImportantProblem,
		// ProblemInfo
	},
	mounted() {
		this.showSearch = $common.isAuth('problem:search');
		this.showAdd = $common.isAuth('problem:add');
		this.showEdit = $common.isAuth('problem:edit');
		this.showClose = $common.isAuth('problem:close');
		this.showCancel = $common.isAuth('problem:cancel');
		this.showLook = $common.isAuth('problem:look');
		this.showImportant = $common.isAuth('problem:important');
		this.showDelete = $common.isAuth('problem:delete');
		if (!this.showImportant && !this.showAdd) {
			this.pageSpan = 24;
		}
		this.pageIndex2 = 1;
		this.getProblemTypeList();
		this.getCategoryList();
		this.getDataList();
		this.getMajorList();
	},
	methods: {
		getProblemTypeList() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getProblemType",
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.problemTypeList = data.body;
				}
			});
		},
		getRowClass(iptFlg) {
			// .spanFontColor1 {
			// 		color: #e7b243;
			// 	}
			// .spanFontColor2 {
			// 		color: #e70013;
			// 	}
			if (iptFlg === '1') {
				return 'spanFontColor1';
			} else if (iptFlg === '2') {
				return 'spanFontColor2';
			} else {
				return 'spanFontColor0';
			}
		},
		problemRecordHandle(id) {
			this.problemRecordVisible = true
			this.$nextTick(() => {
				this.$refs.problemRecord.init(id)
			})
		},
		siteContentViewHeight() {
			var height = this.$store.state.common.documentClientHeight - 340;
			return { height: height + 'px' }
		},
		getTableHeight() {
			return this.$store.state.common.documentClientHeight - 340;
		},
		getCategoryList() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getCategoryList",
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.categoryList = data.body;
					// this.category = this.categoryList[0].displayValue;

				}
			});
		},
		setCategory(value) {
			this.category = value;
			this.pageIndex = 1;
			this.getDataList();
		},
		selGetDataList() {
			this.pageIndex = 1;
			this.getDataList();
		},
		currentChangeHandle(val) {
			this.pageIndex = val
			this.getDataList()
		},
		sizeChangeHandle(val) {
			this.pageSize = val;
			this.pageIndex = 1;
			this.getDataList()
		},
		getDataList() {
			if (this.showSearch) {
				this.$http({
					url: this.$store.state.httpUrl + "/business/probleminfo/list",
					method: "get",
					params: {
						workNo: $common.getItem("workNo"),
						subjectNo: this.subjectNo,
						category: this.category,
						major: this.major,
						poseTimeBegin: this.poseTime[0] ? this.poseTime[0] : "",
						poseTimeEnd: this.poseTime[1] ? this.poseTime[1] : "",
						stateFlg: this.stateFlg == -1 ? '' : this.stateFlg,
						closeTimeBegin: this.closeTime[0] ? this.closeTime[0] : "",
						closeTimeEnd: this.closeTime[1] ? this.closeTime[1] : "",
						probSource: this.probSource,
						limit: this.pageSize,
						page: this.pageIndex
					},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.dataList = data.body.records;
						this.totalPage = data.body.totalCount;
						this.pageIndex2 = this.pageIndex
					}
				});
			}
		},
		downloadHandle() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/probleminfo/download",
				method: 'get',
				params: this.$http.adornParams({
					workNo: $common.getItem("workNo"),
					subjectNo: this.subjectNo,
					category: this.category,
					major: this.major,
					poseTimeBegin: this.poseTime[0] ? this.poseTime[0] : "",
					poseTimeEnd: this.poseTime[1] ? this.poseTime[1] : "",
					stateFlg: this.stateFlg == -1 ? '' : this.stateFlg,
					closeTimeBegin: this.closeTime[0] ? this.closeTime[0] : "",
					closeTimeEnd: this.closeTime[1] ? this.closeTime[1] : "",
					probSource: this.probSource
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					if (this.$store.state.showTestFlag) {
						window.open(data.body)
					} else {
						this.global.downLoadFile(data.body, '问题清单.xlsx');
					}
				}
			})
		},
		getMajorList() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getMajorByWorkNo/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.majorList = data.body;
				}
			});
		},
		handleSelectionChange(selection, item) {
			this.tableSelVal = selection;
			let _tableSelIdArray = [];
			selection.forEach((item) => {
				_tableSelIdArray.push(item.probId)
			})
			this.tableSelIdArray = _tableSelIdArray;
			if (selection.length === this.dataList.length) {
				this.selAllFlg = true;
			} else {
				this.selAllFlg = false;
			}
		},
		tableRowClassName({ row, rowIndex }) {
			let color = this.getRowClass(row.iptFlg);
			return color
		},
		addOrUpdateHandle(id) {
			this.addOrUpdateVisible = true
			this.$nextTick(() => {
				this.$refs.addOrUpdate.init(id)
			})
		},
		closeProblemHandle(id) {
			this.closeProblemVisible = true
			this.$nextTick(() => {
				this.$refs.closeProblem.init(id)
			})
		},
		cancelProblemHandle(id) {
			this.cancelProblemVisible = true
			this.$nextTick(() => {
				this.$refs.cancelProblem.init(id)
			})
		},
		lookProblemHandle(id) {
			this.$router.push({ name: 'problemInfo', query: { probId: id } });
		},
		importantProblemHandle() {
			if (this.tableSelIdArray.length == 0) {
				return
			}
			this.importantProblemVisible = true
			this.$nextTick(() => {
				this.$refs.importantProblem.init(this.tableSelIdArray);
			})
		},
		setStateFlg(flg) {
			this.stateFlg = flg;
		},
		toggleAllSelection() {
			this.$refs.dataListTable.toggleAllSelection();
		},
		resetFormData() {
			this.subjectNo = "";
			this.major = "";
			this.category = '';
			this.probSource = '';
			this.poseTime = [];
			this.stateFlg = '';
			this.closeTime = [];
		}
	}
};
</script>
<style lang="scss" scoped>
.selItemText {
	font-size: 14px;
	color: #1c1c1c;
	display: inline-block;
	width: 80px;
}

.spanFontColor1 {
	color: #e7b243;
}

.spanFontColor2 {
	color: #e70013;
}

.my-pagination {
	margin-right: 20px !important;
}

::v-deep(.el-table th.el-table__cell) {
	background-color: #d3e2f4 !important;
	font-size: 15px;
	padding: 2px 0;
}
</style>
