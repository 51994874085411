<template>
	<el-dialog
		title="标记重要程度"
		:close-on-click-modal="false"
		:visible.sync="visible"
		width="600px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true">
			<el-row>
				<el-col :span="24">
					<el-form-item label="重要程度" prop="iptFlg">
						<el-select class="selItemInput" v-model="dataForm.iptFlg" placeholder="请选择">
							<el-option
							  v-for="item in importantList"
							  :key="item.displayValue"
							  :label="item.displayName"
							  :value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>	
			  <el-row class="formBtnGroup">
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button>
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
			  </el-row>
		</el-form>
	</el-dialog>
</template>

<script>
	import $common from "@/utils/common.js"
	export default {
	  name: "problem-add-or-update",
	  data() {
	    return {
			id: "",
			visible: true,
			form:{},
			importantList:[],
			dataForm:{
				probIds:[],
				iptFlg:""
			},
			dataRule: {
				"iptFlg":[
					{ required: true, message: "请选择重要程度！", trigger: "blur" }
				]
			},
	    };
	  },
	  components: {
	
	  },
	  activated() {
	
	  },
	  methods: {
		init(ids) {
			this.getProblemImportant();
			this.dataForm.probIds = ids;
			this.$nextTick(() => {
				this.visible = true;
			})
			
		},
		getProblemImportant(){
			this.$http({
			  url:  this.$store.state.httpUrl + "/common/getProblemImportant",
			  method: "get",
			  params: {},
			}).then(({ data }) => {
			  if (data && data.resultCode === 200) {
				  this.importantList = data.body;
			  }
			});
		},
		dataFormSubmit() {
		  this.$refs["dataForm"].validate(valid => {
		    if (valid) {
		      this.$http({
		        url: this.$store.state.httpUrl + "/business/probleminfo/updateIptFlg",
		        method: "post",
		        data: this.$http.adornData(this.dataForm)
		      }).then(({ data }) => {
		        if (data && data.resultCode === 200) {
		          this.$message({
		            message: "操作成功",
		            type: "success",
		            duration: 1500,
		            onClose: () => {
		              this.visible = false;
		              this.$emit("refreshDataList");
		            }
		          });
		        } else {
		          this.$message.error(data.msg);
		        }
		      });
		    }
		  });
		}
	  }
	};
</script>

<style lang="scss" scoped="scoped">
	.selItemInput.descAreaLg{
		width: 718px !important;
	}
	.addImgBox{
		width: 136px;
		height: 136px;
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
		position: relative;
	}
</style>
